import * as moment from 'moment';

export class CommonFunctionUtils {
  constructor() {}

  public static calculateTotalCoins(
    discount: any,
    totalPages: any,
    selectedAdvisorsAndPackages: any,
    privateWorkPrice: any = 0
  ) {
    let totalDiscount: any = 0;
    let fixedCoins = 1 + privateWorkPrice;
    let coins = 1 + privateWorkPrice; // Initial value is 3 because of fixed commission
  
    // Calculate coins based on selected advisors and packages
    let totalCoins = selectedAdvisorsAndPackages.map(function (advisor: any) {
      if (advisor.program_logo == null) {
        coins += advisor.selected_package.price;
      }
      return coins;
    });
  
    fixedCoins = coins - fixedCoins;
  
    // If there is a discount and it's not an empty object or false
    if (discount != false && discount != undefined && JSON.stringify(discount) != '{}') {
      let discountAmount = coins;
  
      // Exclude privateWorkPrice from the discount if it's greater than 0
      if (privateWorkPrice > 0) {
        discountAmount -= privateWorkPrice; // Subtract privateWorkPrice from coins before discount
      }
  
      // Apply the discount based on percentage or fixed value
      if (discount.coupon_discount_in_percentage) {
        totalDiscount = (
          (discount.coupon_discount_in_percentage / 100) * discountAmount
        ).toFixed(2);
      } else {
        totalDiscount = discount.coupon_dicount_fix_price.toFixed(2);
      }
  
      // Save discount information to local storage
      localStorage.setItem('discount', JSON.stringify(discount));
      localStorage.setItem('discountPrice', totalDiscount);
    }
  
    let total = {
      totalCoins: totalCoins[totalCoins.length - 1] || 0,
      totalDiscount: totalDiscount,
    };
    return total;
  }

  public static calculateDeadline(created_at: any, time: any) {
    return moment(created_at, 'YYYY-MM-DDTHH:mm:ssZ')
      .add(time, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  public static calculateRemainingTime(deadline: any) {
    let today = moment();
    deadline = moment(deadline);
    return deadline.diff(today, 'seconds');
  }

  public static getTimeFromMinutes(minutes: any) {
    if (minutes == -1) {
      return 'Unavailable';
    }
    let allTimes = [
      { value: '20 minutes', due_time: 20 },
      { value: '40 minutes', due_time: 40 },
      { value: '1 hour', due_time: 60 },
      { value: '2 hours', due_time: 120 },
      { value: '3 hours', due_time: 180 },
      { value: '1 day', due_time: 1440 },
      { value: '2 days', due_time: 2880 },
      { value: '3 days', due_time: 4320 },
      { value: '1 week', due_time: 10080 },
      { value: '2 weeks', due_time: 20160 },
    ];
    let time = allTimes.filter((x: any) => x.due_time == minutes);
    return time[0]?.value;
  }

  public static timeFromNow(time: any) {
    return moment(time).fromNow();
  }

  public static customCountDownConfig(deadline: any = '') {
    let config = {
      leftTime: 60,
      format: 'HH:mm:ss',
      prettyText: (text: any) => {
        return text
          .split(':')
          .map((v: any) => `<span class="item">${v}</span>`)
          .join('');
      },
    };

    if (deadline >= 86400) {
      //day seconds
      config.format = 'dd:HH:mm:ss';
    }
    return config;
  }
}
